import React, { useRef } from "react";
import { Icon } from "@iconify/react";

function Modal({ CloseModal, children, modificationClass = "" }) {
  const currentModalContainer = useRef(null);

  function CloseModalOnClickBackground(e) {
    if (e.target === currentModalContainer.current) {
      CloseModal();
    }
  }

  return (
    <div
      className={"modalContainer " + modificationClass}
      onClick={CloseModalOnClickBackground}
      ref={currentModalContainer}
    >
      <div className={"modalMain " + modificationClass}>
        <div className={"modalMainInner " + modificationClass}>
          {children}
          <div
            className={"modalCloseButton " + modificationClass}
            type="button"
            onClick={CloseModal}
          >
            <Icon icon="akar-icons:circle-x" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
