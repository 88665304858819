import React, { useState } from "react";
import "./App.css";
import axios from "axios";
import Modal from "./Components/Modal";
import FindOutMore from "./Components/FindOutMore";

function App() {
  const [answer, SetAnswer] = useState("");
  const [question, SetQuestion] = useState("");
  const [index, SetIndex] = useState("bible");
  const [modalOpen, SetModalOpen] = useState(false);
  const [email, SetEmail] = useState("");
  const [invalidEmail, SetInvalidEmail] = useState(false);

  async function GetAnswer() {
    if (CheckEmailFormat()) {
      SetAnswer("Thinking...");
      await axios({
        method: "get",
        url: "https://hydrabrain-lx.azurewebsites.net/api/Ask?code=2Euc9WE3SSYgmJ0-gCJrRsLg56Fm7yaskUUq5duBk6MBAzFujQ4qPA==",
        params: {
          question: question,
          index: index,
          emailAddress: email,
        },
      })
        .then(function (response) {
          console.log(response);
          SetAnswer(response.data.messages[2].content);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function ChangeQuestion(e) {
    SetQuestion(e.target.value);
  }

  function ChangeEmail(e) {
    SetEmail(e.target.value);
  }

  function ToggleModal() {
    SetModalOpen(!modalOpen);
  }

  function CheckEmailFormat() {
    SetAnswer("");
    if (email === "") {
      SetInvalidEmail(true);
      alert("Please enter an email address");
      return false;
    }
    if (
      email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) === null
    ) {
      SetInvalidEmail(true);
      alert("Please enter a valid email address");
      return false;
    }
    SetInvalidEmail(false);
    return true;
  }

  return (
    <>
      <div className="mainScreen">
        <div className="header">
          <div className="logo">
            <img
              className="logoImage"
              src="https://www.hammrtech.com/Hammrtech%20Logos/HammrLogo.png"
              alt="logo"
            />
          </div>
          <div className="title">
            <div className="text titleField">HAMMR-GPT</div>
          </div>
        </div>
        <div className="container body">
          <div className="descriptionContainer">
            <div className="text descriptionField">
              HAMMR-GPT is a ChatGPT based AI that can answer questions about
              the Bible and J. R. R. Tolkien's works. There are 2 models
              available: One is trained on the King James Bible and the other on
              The Lord of the Rings trilogy, The Hobbit, and The Silmarillion.
              It is a demonstration of the capabilities of ChatGPT and how it
              can be used to answer questions about any subject. Call us at{" "}
              <a className="text" href="Tel:865-500-5510">
                (865) 500-5510
              </a>{" "}
              to learn how we can train it on your business data.
            </div>
          </div>
          <div className="questionAnswerContainer">
            <div className="container allFields">
              <div className="emailContainer">
                <div className="text emailField">Email</div>
                <input
                  className={invalidEmail ? "email invalid" : "email"}
                  type="email"
                  onChange={ChangeEmail}
                />
              </div>
              <div className="questionContainer">
                <div className="questionTextArea">
                  <textarea
                    className="question"
                    type="multiline"
                    placeholder="Ask a question"
                    onChange={ChangeQuestion}
                  />
                </div>
                <div className="selectorContainer">
                  <div
                    className={
                      index === "bible" ? "selector selected" : "selector"
                    }
                    onClick={() => {
                      SetIndex("bible");
                    }}
                  >
                    <div className="text selectorField">Bible</div>
                  </div>
                  <div
                    className={
                      index === "tolkien" ? "selector selected" : "selector"
                    }
                    onClick={() => {
                      SetIndex("tolkien");
                    }}
                  >
                    <div className="text selectorField">Tolkien</div>
                  </div>
                  <div className="selector" onClick={ToggleModal}>
                    <div className="text selectorField theirData">
                      Your Data
                    </div>
                  </div>
                </div>
                <div className="container button">
                  <div className="buttonField" onClick={GetAnswer}>
                    Ask
                  </div>
                </div>
              </div>
            </div>
            <div className="answerContaniner">
              <div className="answer">{answer}</div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div
            className="text footerField clickable"
            onClick={() => (window.location = "mailto:info@hammrtech.com")}
          >
            Contact Us To Learn How This Can Help Your Business
          </div>
          <div className="text footerField">
            © 2023 Hammrtech Inc. / All rights reserved
          </div>
        </div>
        {modalOpen ? (
          <Modal
            CloseModal={ToggleModal}
            modificationClass="modalContainerAbout"
          >
            <FindOutMore />
          </Modal>
        ) : null}
      </div>
    </>
  );
}

export default App;
