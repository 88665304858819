import React from "react";

function FindOutMore() {
  return (
    <div className="container findOutMore">
      <div className="text findOutMoreTitle">Contact Us</div>
      <div className="text findOutMoreField">
        For a better solution to your business data, email us at{" "}
        <a
          className="text"
          href="mailto:mailto:info@hammrtech.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@hammrtech.com
        </a>{" "}
        or call us at{" "}
        <a className="text" href="tel:865-500-5510">
          (865) 500-5510
        </a>
        . Find out today how we can train HAMMR-GPT on your business data and
        increase your productivity.
      </div>
    </div>
  );
}

export default FindOutMore;
